<template>
	<div class="page container">
		<v2-back-btn />
		<div class="page__info">
			<div class="page__info--title">
				Create New Course
			</div>
		</div>
		<div class="separator" />
		<div class="content create-course">
			<div class="main">
				<v2-course-edit
					ref="editRef"
					cancelRoute="/teaching/courses"
					:isCreate="true"
				/>
			</div>
			<div class="side">

				<div class="create-course--title">
					<icon icon="v2-teacher" colour="transparent" />
					Your New Course
				</div>

				<div class="create-course--controlls">
					<V2Button variant="red" @click="onCreate">
						Create
					</V2Button>
					<V2Button @click="onCancel">
						Cancel
					</V2Button>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import Icon               from '@/components/ui/Icon';
	import V2BackBtn          from '@/components/v2/ui/V2BackBtn';
	import V2CourseEdit from '@/components/v2/courses/V2CourseEdit';
	import V2Button           from '@/components/v2/ui/V2Button';

	export default {
		metaInfo: {
			title: 'Create new course'
		},

		components: {
			V2BackBtn,
			V2CourseEdit,
			Icon,
			V2Button
		},

		layout: 'v2default',

		created () {
			this.$store.commit('ui/setLoadingIsHidden');
		},

		methods: {
			onCreate () {
				this.$refs.editRef.submit();
			},

			onCancel () {
				this.$router.back();
			}
		}
	};
</script>

<style lang="scss" scoped>

.create-course {

  @media (max-width: 1279px) {
    display: flex;
    flex-direction: column;

    .side {
      width: 100%;
    }

    .main {
      width: 100%;
    }
  }

  &--title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    gap: 10px;
    @include font(18px, $c-darkest-navy, 700, 23px);
  }

  &--controlls {
    display: flex;
    gap: 12px;

    .btn {
      border-radius: 4px;
      padding: 8px 20px;
      @include font(16px, $c-white-l, 500, 20px);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-create {
      background: $c-red;
    }

    &-cancel {
      background: $c-dark-navy;
    }
  }
}

</style>
